import React, { type ReactElement } from 'react';
import { type defaultColors } from '../../utils/colors';
import { AnimatedCard } from './AnimatedCard';

interface IconTitleDescriptionCardProps {
  icon: ReactElement<{ className?: string }>;
  title: string;
  description: string;
  index: number;
  colors: typeof defaultColors;
}

export function IconTitleDescriptionCard({ icon, title, description, index, colors }: IconTitleDescriptionCardProps) {
  return (
    <AnimatedCard index={index}>
      <div className="mb-6 p-4 bg-gradient-to-br from-indigo-400 to-purple-500 rounded-full transform group-hover:scale-110 transition-transform duration-300">
        {React.isValidElement(icon) ? React.cloneElement(icon, { className: "h-12 w-12 text-white" }) : null}
      </div>
      <h3 className={`text-2xl font-bold mb-4 ${colors.cardTitle} ${colors.cardTitleHover} transition-colors duration-300`}>{title}</h3>
      <p className={`${colors.cardDescription} ${colors.cardDescriptionHover} transition-colors duration-300`}>{description}</p>
    </AnimatedCard>
  );
}