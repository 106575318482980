'use client'

import {useForm} from "@conform-to/react";
import {getZodConstraint, parseWithZod} from "@conform-to/zod";
import { Wallet, Shield, Brain, Heart, ThumbsUp, Bot } from "lucide-react"
import {FormNames, SignUpFormType} from "#app/components/landing/EmailSignupForm.tsx";
import {SignupSchema} from "#app/routes/_auth+/signup.tsx";
import { defaultBackgroundShapeColors, defaultColors, lightBackgroundShapeColors } from "#app/utils/colors.js";
import { WithBackground } from "./common/WithBackground";
import { BrainIcon, LightbulbIcon, MicIcon } from './icons'
import { Benefits } from "./landing/Benefits"
import { CallToAction } from "./landing/CallToAction"
import { FAQ } from "./landing/FAQ"
import { Footer } from "./landing/Footer"
import { Hero } from "./landing/Hero"
import { HowItWorks } from "./landing/HowItWorks"
import { MultiCallToAction } from "./landing/MultiCallToAction";
import { OurPromises } from "./landing/OurPromises"
import { Partners } from "./landing/Partners"
import { RealResults } from "./landing/RealResults"

const benefits = [
  {
    title: "Instant Emotional Support",
    subtitle: "Always There When You Need It",
    description: "Our chat feature is always on hand to provide instant emotional relief, giving you a safe space to express and feel understood.",
    category: "Support",
    imageSrc: "/img/emotional-support-1.png",
    imageAlt: "Instant emotional support illustration"
  },
  {
    title: "Guided Transformation",
    subtitle: "Your Journey, Personalized",
    description: "Receive insights and tools that are tailored to your unique challenges, sourced from the most effective techniques used by mental health experts.",
    category: "Growth",
    imageSrc: "/img/guided-transformation-1.png",
    imageAlt: "Personal transformation journey illustration"
  },
  {
    title: "Long-term Growth",
    subtitle: "Sustainable Mental Well-being",
    description: "Move beyond quick fixes and prepare for real change. Our guided reflections and activities help you build emotional resilience and positive habits.",
    category: "Well-being",
    imageSrc: "/img/long-term-growth-1.png",
    imageAlt: "Sustainable growth and well-being visualization"
  },
  {
    title: "Deep Healthy Relationships",
    subtitle: "Connect, Understand, and Flourish",
    description: "Develop the skills to build and maintain deep, fulfilling relationships. Our tools help you understand others and communicate effectively, bringing more harmony into your connections.",
    category: "Relationships",
    imageSrc: "/img/deep-healthy-relationships-1.png",
    imageAlt: "Healthy relationships illustration"
  },
  {
    title: "Living with Purpose",
    subtitle: "Pursue What Truly Matters",
    description: "Align your goals with what truly fulfills you. With personalized guidance, take steady steps toward a life that reflects your values and aspirations.",
    category: "Purpose",
    imageSrc: "/img/living-with-purpose-1.png",
    imageAlt: "Living with purpose visualization"
  },
  {
    title: "Living Authentically",
    subtitle: "Embrace Your True Self",
    description: "Break free from the masks and expectations holding you back. Discover who you truly are and gain the courage to live in alignment with your deepest values and desires.",
    category: "Authenticity",
    imageSrc: "/img/living-authentically-1.png",
    imageAlt: "Authentic living representation"
  }
]

const steps = [
  {
    title: "Share your thoughts",
    description: "Speak or type your reflections and journal entries easily.",
    icon: <MicIcon className="h-10 w-10 text-primary" />
  },
  {
    title: "Reflect with AI",
    description: "Our AI will help you uncover insights and reflections.",
    icon: <BrainIcon className="h-10 w-10 text-primary" />
  },
  {
    title: "Act on Expert Tools",
    description: "Receive tailored tools and actions to foster growth.",
    icon: <LightbulbIcon className="h-10 w-10 text-primary" />
  }
]

const faqs = [
  {
    question: "Is this app like therapy?",
    answer: "No, Best of Me offers self-guided tools and reflections for personal growth, not a replacement for professional therapy."
  },
  {
    question: "How is Best of Me different from other wellness apps?",
    answer: "We personalize every step based on your reflections, helping you uncover deeper insights and offering targeted tools—not just surface-level tips."
  }
]

const promises = [
  {
    title: "Judgment-Free Zone",
    description: "We know it can be hard to open up. Best of Me is a place where you're never judged—only understood. We're here to support, not criticize, so you can truly be yourself.",
    children: <Heart className="w-12 h-12 mb-4 text-primary" />
  },
  {
    title: "Expert Tools, Proven Methods",
    description: "Our tools are based on established, effective psychological techniques, used by mental health professionals worldwide. Designed to help you feel better, faster.",
    children: <Brain className="w-12 h-12 mb-4 text-primary" />
  },
  {
    title: "Your Data, Fully Protected",
    description: "Your privacy is our priority. Your data is secure and encrypted, and you stay in full control.",
    children: <Shield className="w-12 h-12 mb-4 text-primary" />
  },
  {
    title: "No Pressure, No Overwhelm",
    description: "Best of Me is designed to fit into your life. You can engage as much or as little as you want, with no pressure and no obligation. Start and stop at your own pace.",
    children: <ThumbsUp className="w-12 h-12 mb-4 text-primary" />
  },
  {
    title: "Fine-Tuned for You, Not the World",
    description: "We use models designed specifically to operate without using your data for training.",
    children: <Bot className="w-12 h-12 mb-4 text-primary" />
  },
  {
    title: "Money-Back Guarantee",
    description: "If you haven’t made noticeable progress within 30 days, we’ll refund your subscription—no questions asked. We’re confident in our ability to support you on your journey.",
    children: <Wallet className="w-12 h-12 mb-4 text-primary" />
  },
]

export interface LandingPageProps {
  actionData?: {
    intent: FormNames;
    result?: any;
  };
}

const useSignupForm = (id: string, actionData?: any) => {
  return useForm({
    id: id,
    constraint: getZodConstraint(SignupSchema),
    lastResult: actionData?.result,
    onValidate({ formData }) {
      const result = parseWithZod(formData, { schema: SignupSchema });
      return result;
    },
    shouldRevalidate: 'onBlur',
  });
};

export function LandingPage({ actionData }: LandingPageProps) {

  const [heroForm, heroFields] = useSignupForm(
    'hero-form-id',
    actionData?.intent === FormNames.HERO_SIGNUP_FORM ? actionData : undefined
  );
  const [struggleForm, struggleFields] = useSignupForm(
    'struggle-form-id',
    actionData?.intent === FormNames.STRUGGLE_SIGNUP_FORM ? actionData : undefined
  );
  const [readyForm, readyFields] = useSignupForm(
    'ready-form-id',
    actionData?.intent === FormNames.READY_SIGNUP_FORM ? actionData : undefined
  );
  const [feelTheChangeForm, feelTheChangeFields] = useSignupForm(
    'feel-the-change-form-id',
    actionData?.intent === FormNames.FEEL_THE_CHANGE_SIGNUP_FORM ? actionData : undefined
  );

  const options = [
    'Find Balance',
    'Feel Love',
    'Stay Focused',
    'Manage Stress',
    'Feel Fulfilled',
    'Feel Joy'
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Hero 
        form={heroForm as SignUpFormType} 
        fields={heroFields}
        actionData={actionData}
      />
      <WithBackground type="shapes" colors={defaultColors} backgroundShapeColors={defaultBackgroundShapeColors} className="">
        <Benefits benefits={benefits} />
      </WithBackground>

      <MultiCallToAction
        form={struggleForm as SignUpFormType}
        fields={struggleFields}
        description="Life can be overwhelming, but you're not alone. Best of Me provides personalized support to help you reflect, gain insights, and grow at your own pace."
        formId="struggle-signup-form"
        options={options}
        formName={FormNames.STRUGGLE_SIGNUP_FORM}
      />
      <RealResults background="bg-white" />

      <OurPromises promises={promises}/>

      <CallToAction 
        form={feelTheChangeForm as SignUpFormType}
        fields={feelTheChangeFields}
        title="Step Confidently Into Your Journey"
        description="You've found a space built for your growth. With complete support, privacy, and tools designed just for you, now is the time to start feeling better."
        formId="feel-the-change-form"
        submitButtonText="Begin Your Journey Now"
        formName={FormNames.FEEL_THE_CHANGE_SIGNUP_FORM}
        actionData={actionData}
      />
      <WithBackground type="shapes" 
        colors={defaultColors} 
        backgroundShapeColors={lightBackgroundShapeColors} className="py-8 md:py-16 lg:py-24">
        <HowItWorks steps={steps} />
      </WithBackground>
      <FAQ faqs={faqs} />
      <Partners background="bg-white" />
      <CallToAction
        form={readyForm as SignUpFormType}
        fields={readyFields}
        title="Ready to Become the Best Version of Yourself?"
        description="Start your journey today. Free trial, no credit card required."
        formId="ready-signup-form"
        submitButtonText="Start Your Free Trial"
        formName={FormNames.READY_SIGNUP_FORM}
        actionData={actionData}
      />
    </div>
  )
}
