import { ReactNode } from 'react';
import { defaultColors, defaultBackgroundShapeColors } from '#/app/utils/colors';
import { BackgroundShapes } from './BackgroundShapes';
import { cn } from '#/app/utils/misc';

interface SectionLayoutProps {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
}

// Internal helper component for layout consistency
function SectionLayout({ 
  children, 
  className,
  containerClassName = "container px-4 md:px-6 mx-auto"
}: SectionLayoutProps) {
  return (
    <div className={containerClassName}>
      {children}
    </div>
  );
}

interface BaseSectionProps {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
}

export function Section({ 
  children, 
  className = "py-16 md:py-24 lg:py-32",
  containerClassName
}: BaseSectionProps) {
  return (
    <section className={cn("w-full", className)}>
      <SectionLayout containerClassName={containerClassName}>
        {children}
      </SectionLayout>
    </section>
  );
}

export interface WithColors {
  colors?: typeof defaultColors;
}

interface BackgroundShapesConfig extends WithColors {
  type: 'shapes';
  backgroundShapeColors?: typeof defaultBackgroundShapeColors;
}

interface BackgroundColorConfig {
  type: 'color';
  background: string;
}

type WithBackgroundProps = BaseSectionProps & (BackgroundShapesConfig | BackgroundColorConfig);

export function WithBackground({ 
  children, 
  className = "py-16 md:py-24 lg:py-32",
  containerClassName,
  ...config
}: WithBackgroundProps) {
  if (config.type === 'shapes') {
    const { colors = defaultColors, backgroundShapeColors = defaultBackgroundShapeColors } = config;
    return (
      <section className={cn(`
        w-full 
        bg-gradient-to-br 
        ${colors.backgroundGradient.from} 
        ${colors.backgroundGradient.via} 
        ${colors.backgroundGradient.to} 
        relative 
        overflow-hidden`,
        className
      )}>
        <BackgroundShapes colors={backgroundShapeColors} />
        <div className="relative z-10">
          <SectionLayout containerClassName={containerClassName}>
            {children}
          </SectionLayout>
        </div>
      </section>
    );
  }

  return (
    <section className={cn("w-full", config.background, className)}>
      <SectionLayout containerClassName={containerClassName}>
        {children}
      </SectionLayout>
    </section>
  );
} 