import React from "react"
import { AnimatedTitle } from "#/app/components/common/AnimatedTitle"
import { Section, type WithColors } from "#/app/components/common/WithBackground"
import { defaultColors } from "#app/utils/colors.js"

interface Promise {
  title: string
  description: string
  children: React.ReactNode
}

interface OurPromisesProps extends WithColors {
  promises: Promise[]
}

export function OurPromises({ promises, colors = defaultColors }: OurPromisesProps) {
  return (
    <Section>
      <AnimatedTitle className={colors.title}>Our Promises to You</AnimatedTitle>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {promises.map((promise, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {promise.children}
            <h3 className="text-xl font-bold mb-2">{promise.title}</h3>
            <p className="text-muted-foreground">{promise.description}</p>
          </div>
        ))}
      </div>
    </Section>
  )
}