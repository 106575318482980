import React from 'react';
import { defaultBackgroundShapeColors } from '../../utils/colors';

interface BackgroundShapesProps {
  className?: string;
  colors?: typeof defaultBackgroundShapeColors;
}

export function BackgroundShapes({ className = '', colors = defaultBackgroundShapeColors }: BackgroundShapesProps) {
  return (
    <div className={`absolute inset-0 overflow-hidden opacity-10 ${className}`}>
      <div className={`absolute -left-1/4 -top-1/4 w-1/2 h-1/2 ${colors.shape1} rounded-full mix-blend-multiply filter blur-xl animate-blob`}></div>
      <div className={`absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 ${colors.shape2} rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000`}></div>
      <div className={`absolute left-1/4 bottom-1/4 w-1/2 h-1/2 ${colors.shape3} rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000`}></div>
    </div>
  );
}