import React, { type ReactElement } from 'react';
import { defaultColors } from '../../utils/colors';
import { AnimatedTitle } from '../common/AnimatedTitle';
import { IconTitleDescriptionCard } from '../common/IconTitleDescriptionCard';
import { type WithColors } from '../common/WithBackground';
import { Section } from '../common/WithBackground';
import { cn } from '../../utils/misc';

interface Step {
  title: string;
  description: string;
  icon: ReactElement;
}

interface HowItWorksProps extends WithColors {
  steps: Step[];
  className?: string;
}

export function HowItWorks({ 
  steps, 
  colors = defaultColors,
  className 
}: HowItWorksProps) {
  return (
    <Section className={cn(className)}>
      <AnimatedTitle className={colors.title}>How Does Best of Me Work?</AnimatedTitle>
      {steps.length > 0 ? (
        <div className="grid gap-10 lg:grid-cols-3 lg:gap-16">
          {steps.map((step, index) => (
            <IconTitleDescriptionCard
              key={index}
              icon={step.icon}
              title={step.title}
              description={step.description}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">No steps available at the moment.</p>
      )}
    </Section>
  );
}