import React from 'react';
import { Form } from "@remix-run/react";
import { Button } from "#app/components/ui/button.tsx";
import { ArrowRight } from "lucide-react";
import { Field } from "#app/components/forms.tsx";
import { getInputProps } from "@conform-to/react";
import type { FieldMetadata } from "@conform-to/react";
import { FormMetadata, getFormProps } from "@conform-to/react";
import { cn } from '#app/utils/misc';
import { Image } from "#app/components/Image.tsx";

export type SignUpFormType = FormMetadata<{
  email: string;
  intent: FormNames;
}, string[]>;

export enum FormNames {
  HERO_SIGNUP_FORM = 'heroSignupForm',
  STRUGGLE_SIGNUP_FORM = 'struggleSignupForm',
  READY_SIGNUP_FORM = 'readySignupForm',
  FEEL_THE_CHANGE_SIGNUP_FORM = 'feelTheChangeSignupForm',
}

interface EmailSignupFormProps {
  form: SignUpFormType;
  fields: {
    email: FieldMetadata<string>;
  };
  submitButtonText: string;
  className?: string;
  formName: FormNames;
  showArrowImage?: boolean;
}

export function EmailSignupForm({ form, fields, submitButtonText, className, formName, showArrowImage = false }: EmailSignupFormProps) {
  return (
    <Form method="POST" 
          {...getFormProps(form)} 
          className={cn(
            "flex flex-col gap-4 sm:flex-row sm:items-start pt-4", 
            className
          )}
    >
      <div className="flex-grow w-full">
        <input type="hidden" name="intent" value={formName} />
        <Field
          labelProps={{
            htmlFor: `${formName}-${fields.email.id}`,
            children: '', // no label
            className: 'sr-only',
          }}
          inputProps={{
            ...getInputProps(fields.email, { type: 'email' }),
            placeholder: 'Enter your email',
            name: 'email',
            id: `${formName}-${fields.email.id}`,
            autoComplete: 'email',
            className: 'w-full px-3 py-2 border border-gray-300 rounded-md text-base text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary',
          }}
          errors={fields.email.errors}
        />
      </div>
      <div className="flex items-center gap-2">
        <Button type="submit" className="w-full sm:w-auto">
          {submitButtonText}
          {/* <ArrowRight className="ml-2 h-4 w-4" /> */}
        </Button>
        {showArrowImage && (
          <Image
            src="/img/arrow-left.webp"
            alt="Arrow pointing to CTA"
            width={50}
            height={50}
            className="animate-bounce hidden sm:block"
          />
        )}
      </div>
    </Form>
  );
}