import React from 'react';
import {cn} from "#app/utils/misc.tsx";

interface PartnersProps {
  background: string;
}

const partners = [
  { name: 'Perry Talents', logo: '/img/partners/perry_talents_color.jpeg', url: 'https://perrytalents.com/', width: 'w-64', alt: 'Perry Talents logo' },
  { name: 'HealthHub', logo: '/img/partners/healthhub-logo.svg', url: 'https://www.healthhub.sk/en/', width: 'w-64', alt: 'HealthHub logo' },
];

export function Partners({ background }: PartnersProps) {
  return (
    <section className={`py-16 ${background}`}>
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-8">Our Trusted Partners</h2>
        <div className="flex flex-wrap justify-center items-center gap-16">
          {partners.map((partner, index) => (
            <a
              key={index}
              href={partner.url}
              target="_blank"
              rel="noopener noreferrer"
              className={cn('h-32 flex items-center justify-center transition-transform hover:scale-105', partner.width)}
            >
              <img
                src={partner.logo}
                alt={partner.alt}
                className="max-w-full max-h-full object-contain"
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}