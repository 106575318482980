import React from 'react';
import { AnimatedCard } from '../common/AnimatedCard';
import { AnimatedTitle } from '../common/AnimatedTitle';
import { Section } from '../common/WithBackground';

interface Benefit {
  title: string;
  subtitle: string;
  description: string;
  category?: string;
  imageSrc?: string;
  imageAlt?: string;
}

interface BenefitsProps {
  benefits: Benefit[];
}

export function Benefits({ benefits }: BenefitsProps) {
  return (
    <Section>
      <div className="flex flex-col items-center justify-center text-center">
        <AnimatedTitle>
          What You Get with Best of Me
        </AnimatedTitle>
      </div>

      {benefits.length > 0 ? (
        <div className="grid gap-6 lg:grid-cols-3 lg:gap-8">
          {benefits.map(({ title, subtitle, description, category, imageSrc = "/images/emotional-support-1.png", imageAlt = title }, index) => (
            <BenefitCard 
              key={index}
              {...{ title, subtitle, description, category, imageSrc, imageAlt, index }} 
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">No benefits available at the moment.</p>
      )}
    </Section>
  );
}

interface BenefitCardProps {
  title: string;
  subtitle: string;
  description: string;
  category?: string;
  imageSrc: string;
  imageAlt: string;
  index: number;
}

function BenefitCard({ title, subtitle, description, category, imageSrc, imageAlt, index }: BenefitCardProps) {
  return (
    <AnimatedCard index={index} classNameCard="p-0">
      <div className="flex flex-col bg-white rounded-lg overflow-hidden h-full">
        <div className="relative h-[300px] overflow-hidden group">
          <img 
            src={imageSrc} 
            alt={imageAlt}
            className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/30 to-transparent" />
          
          <div className="relative p-6 z-10">
            {category && (
              <span className="inline-block px-4 py-1 mb-4 text-sm font-medium rounded-full bg-white/10 text-white">
                {category}
              </span>
            )}
            <h2 className="text-2xl md:text-3xl font-bold mb-2 text-white">
              {title}
            </h2>
            <h3 className="text-xl md:text-2xl font-semibold text-white/90">
              {subtitle}
            </h3>
          </div>
        </div>

        <div className="p-6 pt-4 bg-white flex-1">
          <p className="text-base text-gray-700 leading-relaxed">
            {description}
          </p>
        </div>
      </div>
    </AnimatedCard>
  );
}
