import { cn } from "#app/utils/misc"
import { motion } from "framer-motion"

interface ResultStep {
  title: string
  description: string
  imageSrc: string
  imageAlt: string
}

interface RealResultsProps {
  background?: string
}

export function RealResults({ background = "bg-white" }: RealResultsProps) {
  const results: ResultStep[] = [
    {
      title: "Professional Tools, Proven Results",
      description: "Access the same powerful techniques used by mental health professionals, built on research from top institutions.",
      imageSrc: "/img/how/dream-outcome-2.png",
      imageAlt: "Visualization of personal transformation and growth"
    },
    {
      title: "Tailored for Your Best Results",
      description: "Every step is designed around your unique situation, considering you as a whole person.",
      imageSrc: "/img/how/trust.png",
      imageAlt: "Illustration of proven success path"
    },
    {
      title: "Feel Better, Faster",
      description: "No need to wait weeks for the support you need. Get immediate relief and insights tailored to you, so you can start seeing meaningful progress from day one.",
      imageSrc: "/img/how/speed.png",
      imageAlt: "Quick wins and progress visualization"
    },
    {
      title: "Convenient and Flexible",
      description: "Best of Me fits effortlessly into your life. Engage at your own pace—whether it's a few minutes or longer—without feeling overwhelmed.",
      imageSrc: "/img/how/convenience.png",
      imageAlt: "Effortless integration into daily life"
    }
  ]

  return (
    <section className={cn("py-16 sm:py-20", background)}>
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            How Best of Me Drives Real Results
          </h2>
        </div>
        <div className="mx-auto max-w-4xl mt-12 sm:mt-16 relative">
          <div className="absolute inset-0 w-full" style={{ height: '100%' }}>
            <svg 
              className="w-full h-full"
              viewBox="0 0 1000 1000"
              preserveAspectRatio="none"
            >
              <motion.path
                initial={{ strokeDashoffset: 800 }}
                animate={{ strokeDashoffset: 0 }}
                transition={{ duration: 2, ease: "easeInOut" }}
                d="M 500,0 
                   C 700,150 300,350 500,500
                   C 700,650 300,850 500,1000"
                fill="none"
                stroke="rgb(209, 213, 219)"
                strokeWidth="4"
                strokeDasharray="40 40"
                strokeLinecap="round"
                className="text-primary"
                style={{
                  vectorEffect: 'non-scaling-stroke',
                }}
              />
            </svg>
          </div>

          <div className="grid gap-24">
            {results.map((result, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="grid gap-6 lg:grid-cols-2 items-center relative"
              >
                <div className={cn(
                  "space-y-3",
                  index % 2 === 1 && "lg:order-2"
                )}>
                  <h3 className="text-2xl font-bold">{result.title}</h3>
                  <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                    {result.description}
                  </p>
                </div>
                <div className={cn(
                  "relative group mx-auto lg:mx-0",
                  index % 2 === 1 && "lg:order-1"
                )}>
                  <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-primary/50 to-primary opacity-25 blur transition duration-1000 group-hover:opacity-75 group-hover:duration-200" />
                  <img
                    src={result.imageSrc}
                    alt={result.imageAlt}
                    className="relative rounded-lg object-cover w-full max-w-sm aspect-[4/3] shadow-xs"
                    width={400}
                    height={300}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
} 