import { type FieldMetadata } from "@conform-to/react";
import {type LandingPageProps} from "#app/components/landing-page";
import {EmailSignupForm, FormNames, type SignUpFormType} from "./EmailSignupForm.tsx";

interface CallToActionProps extends LandingPageProps {
  form: SignUpFormType;
  fields: { email: FieldMetadata<string> };
  title: string;
  description: string;
  formId: string;
  submitButtonText?: string;
  formName: FormNames;
}

export function CallToAction({ form, fields, title, description, formId, submitButtonText, formName }: CallToActionProps) {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
              {title}
            </h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              {description}
            </p>
          </div>
          {/* <SignUpForm form={form} fields={fields} id={formId} /> */}
          <EmailSignupForm
            form={form}
            fields={{ email: fields.email }}
            submitButtonText={submitButtonText || 'Get Started Now'}
            className="max-w-md mx-auto"
            formName={formName}
          />
        </div>
      </div>
    </section>
  )
}