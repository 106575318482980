import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

interface AnimatedCardProps {
  children: ReactNode;
  index: number;
  classNameCard?: string;
}

export function AnimatedCard({ children, index, classNameCard }: AnimatedCardProps) {

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: 5, transition: { duration: 0.2 } }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className={twMerge("flex flex-col items-center text-center p-6 bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 group", classNameCard)}
    >
      {children}
    </motion.div>
  );
}