import { type FieldMetadata } from "@conform-to/react";
import { type LandingPageProps } from "#app/components/landing-page";
import { EmailSignupForm, FormNames, type SignUpFormType } from "./EmailSignupForm.tsx";
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useEffect, useRef, useState } from "react";

interface MultiCallToActionProps extends LandingPageProps {
  form: SignUpFormType;
  fields: { email: FieldMetadata<string> };
  description: string;
  formId: string;
  submitButtonText?: string;
  options: string[];
  formName: FormNames;
}

export function MultiCallToAction({ 
  form, 
  fields, 
  description, 
  formId, 
  submitButtonText,
  options,
  formName,
}: MultiCallToActionProps) {
  const [maxWidth, setMaxWidth] = useState(0);
  const measureRef = useRef<HTMLSpanElement>(null);
  
  useEffect(() => {
    if (measureRef.current) {
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.position = 'absolute';
      span.style.whiteSpace = 'nowrap';
      span.style.fontSize = window.getComputedStyle(measureRef.current).fontSize;
      span.style.fontWeight = window.getComputedStyle(measureRef.current).fontWeight;
      span.style.fontFamily = window.getComputedStyle(measureRef.current).fontFamily;
      document.body.appendChild(span);

      const width = options.reduce((max, text) => {
        span.textContent = text;
        return Math.max(max, span.getBoundingClientRect().width);
      }, 0);

      document.body.removeChild(span);
      setMaxWidth(width + 32); // Added padding for the rounded box
    }
  }, [options]);

  const [text] = useTypewriter({
    words: options,
    loop: true,
    delaySpeed: 2000,
    deleteSpeed: 50,
    typeSpeed: 50,
  });

  return (
    <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
              Struggling to{' '}
              <span 
                className="relative inline-block bg-white rounded-lg shadow-sm"
                style={{ width: maxWidth ? `${maxWidth}px` : 'auto' }}
              >
                <span 
                  ref={measureRef}
                  className="text-indigo-900 relative inline-block whitespace-nowrap px-4 py-1"
                  style={{ width: maxWidth ? `${maxWidth}px` : 'auto' }}
                >
                  <span className="flex justify-center">
                    {text}
                    <Cursor cursorStyle="_" />
                  </span>
                </span>
              </span>
              {' '}in a Fast-Paced World?
            </h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              {description}
            </p>
          </div>
          <EmailSignupForm
            form={form}
            fields={{ email: fields.email }}
            submitButtonText={submitButtonText || 'Get Started Now'}
            className="max-w-md mx-auto"
            formName={formName}
          />
        </div>
      </div>
    </section>
  );
} 