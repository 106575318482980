'use client'

import {getFormProps, getInputProps} from "@conform-to/react";
import { Form } from "@remix-run/react"
import { ArrowRight } from "lucide-react"
import React from "react";
import { HoneypotInputs } from "remix-utils/honeypot/react"
import { Image } from "#/app/components/Image"
import { Avatar } from "#/app/components/ui/Avatar"
import { Button } from "#/app/components/ui/button"
import {ErrorList, Field} from "#app/components/forms.tsx";
import {EmailSignupForm, FormNames, SignUpFormType} from "#app/components/landing/EmailSignupForm.tsx";
import { SignUpForm } from "#app/components/landing/SignUpForm.tsx";
import {type LandingPageProps} from "#app/components/landing-page.tsx";
import ImageCarousel from "../ImageCarousel"
import { FieldMetadata } from "@conform-to/react"

const Laurel = () => (
  <div className="w-full max-w-md">
    <section className="relative w-full flex justify-center">
      <div className="laurel relative w-64 mb-2 text-gray-800">
        <img src="/img/small-leaves.svg" alt="Decorative leaves"
             className="laurel-img w-full scale-x-125 origin-center"/>
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <span className="text-xs font-bold">
              #1 App with Lasting results
          </span>
          <span className="mt-1">
              {'⭐️⭐️⭐️⭐️⭐️'}
          </span>
          <span className="mt-1 text-[11px] font-semibold">
              $817 saved on therapy per month
          </span>
        </div>
      </div>
    </section>
  </div>
)

interface HeroProps {
  form: SignUpFormType
  fields: Required<{
    email: FieldMetadata<string, { email: string }, string[]>
    intent: FieldMetadata<string, { intent: string }, string[]>
  }>
  actionData?: LandingPageProps['actionData']
}

export function Hero({ form, fields, actionData }: HeroProps) {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-background">
      <div className="container md:px-20 mx-auto max-w-7xl">
        <div className="grid gap-8 lg:grid-cols-[1fr_1fr] lg:gap-12 items-center">
          <div className="flex flex-col justify-center space-y-6 max-w-2xl">
            <div className="space-y-4">
              <Laurel/>
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                Affordable Therapy without the Wait
              </h1>
              <p className="max-w-[600px] text-muted-foreground md:text-xl">
                Get instant relief through chat, and achieve lasting personal growth with powerful tools.
              </p>
              <EmailSignupForm
                form={form}
                fields={{ email: fields.email }}
                submitButtonText="Start feeling better"
                formName={FormNames.HERO_SIGNUP_FORM}
                showArrowImage={true}
              />
              <div className="flex text-sm items-center mt-0">
                <div className="flex -space-x-2 overflow-hidden">
                  <Avatar
                    alt="Happy customer 1"
                    src="https://bestofme-epic-ai.vercel.app/img/face1-no-bg.png"
                    className="bg-white"
                  />
                  <Avatar
                    alt="Happy customer 2"
                    src="https://bestofme-epic-ai.vercel.app/img/face2-no-bg.png"
                    className="bg-white"
                  />
                  <Avatar
                    alt="Happy customer 3"
                    src="https://bestofme-epic-ai.vercel.app/img/face3-no-bg.png"
                    className="bg-white"
                  />
                </div>
                <span className="text-muted-foreground ml-2">You're not alone on this journey</span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <ImageCarousel images={[
              "/img/face1-no-bg.png",
              "/img/face2-no-bg.png",
              "/img/face3-no-bg.png"
            ]}/>
          </div>
        </div>
      </div>
    </section>
  )
}