import { Image } from "#/app/components/Image"

interface AvatarProps {
  src: string;
  alt: string;
  className?: string;
}

export function Avatar({ src, alt, className = "" }: AvatarProps) {
  return (
    <Image
      alt={alt}
      className={`inline-block h-8 w-8 rounded-full ring-2 ring-background ${className}`}
      height={32}
      src={src}
      style={{
        aspectRatio: "32/32",
        objectFit: "cover",
      }}
      width={32}
    />
  )
}